<template>
  <div>
    <div class="common-header">{{ titleData[operType] }}</div>
    <el-form label-width="80px" ref="form" :model="formData" :rules="rules">
      <el-form-item label="模板名称:" prop="name">
        <el-input v-model="formData.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="模板编码:" prop="code">
        <el-input v-model="formData.code" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input v-model="formData.remark" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save" :loading="loading">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Api from '@/api/devops/pageModule'
export default {
  props: {
    detail: { default: () => {}, type: Object },
    operType: { type: String, default: 'add' },
  },
  data() {
    return {
      formData: {},
      titleData: {
        add: '新增模板',
        edit: '修改模板',
      },
      rules: {
        name: [{ required: true, message: '请输入模板名称', trigger: ['blur', 'change'] }],
        code: [{ required: true, message: '请输入模板编码', trigger: ['blur', 'change'] }],
      },
      loading: false,
      timeout: null,
    }
  },
  watch: {
    detail(val) {
      this.formData = _.isEmpty(val) ? {} : _.cloneDeep(val)
      this.$refs['form'].resetFields()
    },
  },

  methods: {
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            this.formData = {
              engineVersionId: this.$route.query.engineVersionId,
              ...this.formData
            }
            if (this.operType === 'add') {
              await Api.addPageModule(this.formData)
            } else {
              await Api.updatePageModule(this.formData)
            }
            this.$msg.success('保存成功！')
            this.$emit('save')
          } finally {
            this.loading = false
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
</style>
