import http from '@/utils/axios'

const data = {
  getPageModule: (data) => http.post('/h5-app/pageTemplate/listPage', data),
  addPageModule: (data) => http.post('/h5-app/pageTemplate/create', data),
  updatePageModule: (data) => http.post('/h5-app/pageTemplate/update', data),
  deletePageModule: (id) => http.get(`/h5-app/pageTemplate/delete/${id}`),
}

export default data
