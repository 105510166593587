<!-- 页面模板 -->
<template>
  <div class="h100">
    <div  class="h100 flex page-wrapper">
      <common-layout class="flex-1 flex-column h100 w-0">
        <template #header>
          <el-form inline @submit.native.prevent>
            <el-form-item>
              <el-button type="primary" @click="handleEvent('add')">新增</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #default>
          <el-table ref="table" :data="tableData" border highlight-current-row height="100%" v-loading="loading">
            <el-table-column type="index" width="80" label="序号" align="center"></el-table-column>
            <el-table-column v-for="item in tableHeader" :key="item.code" :prop="item.code" :label="item.name" align="center" :min-width="item.width" />
            <el-table-column label="操作" align="center" width="140" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="handleEvent('edit', scope.row)">修改</el-button>
                <el-button type="text" @click="handleEvent('del', scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template #footer>
          <el-pagination :page-size="page.pageSize" :current-page="page.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
        </template>
      </common-layout>
      <w-form-box :operType="operType" :detail="curRow" @save="save" style="width: 300px" class="ml10 pl10 border-left-line h100 overflow-auto" />
    </div>
  </div>
</template>

<script>
import WFormBox from './formBox.vue'

import Api from '@/api/devops/pageModule'

export default {
  components: { WFormBox },
  data() {
    return {
      loading: false,
      tableData: [],
      tableHeader: [
        { code: 'name', name: '模板名称', width: 150 },
        { code: 'code', name: '模板编码', width: 150 },
        { code: 'remark', name: '备注', width: 120 },
        { code: 'createdBy', name: '创建账号', width: 100 },
        { code: 'createdTime', name: '创建时间', width: 120 },
        { code: 'updatedBy', name: '修改账号', width: 100 },
        { code: 'updatedTime', name: '修改时间', width: 120 },
      ],
      operType: 'add',
      curRow: {},
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
    }
  },
  created() {
    this.query()
  },
  methods: {
    async query(pageNum) {
      try {
        this.loading = true
        pageNum && this.$set(this.page, 'pageNum', pageNum)
        const param = {
          engineVersionId: this.$route.query.engineVersionId,
          ...this.page,
        }
        const { data } = await Api.getPageModule(param)
        this.tableData = data.dataList || []
        this.total = data.totalCount
      } finally {
        this.loading = false
      }
    },

    handleEvent(type, row) {
      switch (type) {
        case 'add':
          this.operType = type
          this.curRow = {}
          break
        case 'edit':
          this.operType = type
          this.curRow = { ...row }
          break
        case 'del':
          this.$confirm(`确定删除此模板吗！`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            try {
              this.tableLoading = true
              await Api.deletePageModule(row.id)
              this.$msg.success('删除成功！')
              await this.query(1)
            } finally {
              this.tableLoading = false
            }
          })
          break
      }
    },
    save() {
      this.curRow = {}
      this.operType = 'add'
      this.query(1)
    },
    handleSizeChange(val) {
      this.$set(this.page, 'pageSize', val)
      this.$set(this.page, 'pageNum', 1)
      this.query(1)
    },
    handleCurrentChange(val) {
      this.$set(this.page, 'pageNum', val)
      this.query(val)
    },
  },
}
</script>
<style lang="scss" scoped></style>
